import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import JsBridge from './utils/JsBridge'

import 'lib-flexible'
import '@/styles/index.scss'
import Vant from 'vant'
import 'vant/lib/index.css'
import md5 from 'js-md5'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
// import wx from "weixin-js-sdk";
// Vue.prototype.$wx = wx;

import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$jsbridge = JsBridge

Vue.prototype.$md5 = md5
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'zhulong'
  }
  next()
})
Vue.use(Vant)
// Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
