import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
Vue.use(VueRouter)
import WechatState from '@/utils/isWechatState.js'

const routes = [
  {
    path: '/login',
    name: 'login_index',
    component: () =>
      import('@/views/login/index_new.vue') // 登录页面
  },
  {
    path: '/old_login',
    name: 'old_login',
    component: () =>
      import('@/views/login/old_login.vue') // 登录页面
  },
  {
    path: '/other_login',
    name: 'other_login',
    component: () =>
      import('@/views/login/other_login.vue') // 账号密码登录页面
  },
  {
    path: '/wx_go_login',
    name: 'wx_go_login',
    component: () =>
      import('@/views/login/wx_login.vue') // 登录页面
  },
  {
    path: '/app_loading',
    name: 'app_loading',
    component: () => import('@/views/appView/loading.vue') // app
  },
  // 忘记密码
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    meta: {
      title: '忘记密码'
    },
    component: () => import('../views/login/forgetPassword.vue')
  },
  // {
  //   path: '/changePassword',
  //   name: 'ChangePassword',
  //   component: () =>
  //     import('@/views/login/changePassword.vue') // 修改密码
  // },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: () =>
      import('@/views/login/changePwd.vue') // 修改密码1
  },
  {
    path: '/login/register',
    name: 'register',
    component: () =>
      import('@/views/login/register.vue') // 注册新账号
  },
  {
    path: '/login/CreateAccount',
    name: 'CreateAccount',
    component: () =>
      import('@/views/login/CreateAccount.vue') // 创建账号
  },
  {
    path: '/mine',
    name: 'Home',
    component: () =>
      import('@/views/home/index'),
    meta: {
      title: '个人首页',
      content: {
        keywords: '',
        description: ''
      }
    }
  },

  // ------------------礼品兑换 star--------------------
  {
    path: '/giftinfo', // 礼品详情GiftIndex
    name: 'giftinfo',
    meta: {
      // 页面标题title
      title: '礼品详情'
    },
    component: () =>
      import('@/views/Gift/giftinfo')
  },
  {
    path: '/gift/giftExchange', // 礼品详情GiftIndex
    name: 'GiftExchange',
    meta: {
      // 页面标题title
      title: '礼品详情'
    },
    component: () =>
      import('@/views/Gift/zl_jd_giftinfo')
  },
  // 下面这个礼品详情页兼容app的地址，后期app要改
  {
    path: '/giftExchange', // 礼品详情GiftIndex
    name: 'GiftExchange',
    meta: {
      // 页面标题title
      title: '礼品详情'
    },
    component: () =>
      import('@/views/Gift/zl_jd_giftinfo')
  },
  {
    path: '/gift/RedeemNow',
    name: 'RedeemNow',
    meta: {
      title: '礼品兑换'
    },
    component: () => import('@/views/Gift/RedeemNow.vue')
  },
  {
    path: '/gift', // 礼品首页
    name: 'Gift',
    meta: {
      // 页面标题title
      title: '礼品兑换'
    },
    component: () =>
      import('@/views/Gift/GiftIndex')
  },
  {
    path: '/ExchangeRecord', // 兑换记录
    name: 'ExchangeRecord',
    meta: {
      // 页面标题title
      title: '兑换记录'
    },
    component: () =>
      import('@/views/Gift/ExchangeRecord')
  },
  {
    path: '/jdList', // 京东礼品列表
    name: 'jdList',
    meta: {
      // 页面标题title
      title: '礼品兑换'
    },
    component: () =>
      import('@/views/Gift/jdList')
  },
  {
    path: '/zlList', // 筑龙原创礼品列表
    name: 'zlList',
    meta: {
      // 页面标题title
      title: '礼品兑换'
    },
    component: () =>
      import('@/views/Gift/zlList')
  },
  {
    path: '/gift/RedeemNow_jdyx', // 立即兑换
    name: 'RedeemNow_jdyx',
    meta: {
      // 页面标题title
      title: '立即兑换'
    },
    component: () =>
      import('@/views/Gift/RedeemNow_jdyx')
  },
  {
    path: '/SuccessfulExchange', // 兑换成功
    name: 'SuccessfulExchange',
    meta: {
      // 页面标题title
      title: '兑换成功'
    },
    component: () =>
      import('@/views/Gift/SuccessfulExchange')
  },
  {
    path: '/jdAddressList', // 京东地址列表
    name: 'jdAddressList',
    meta: {
      // 页面标题title
      title: '地址管理'
    },
    component: () =>
      import('@/views/Gift/jdAddressList')
  },
  {
    path: '/addAddress', // 京东添加地址
    name: 'addAddress',
    meta: {
      // 页面标题title
      title: '添加地址'
    },
    component: () =>
      import('@/views/Gift/addAddress')
  },
  {
    path: '/searchResult', // 搜索结果
    name: 'searchResult',
    meta: {
      // 页面标题title
      title: '礼品搜索'
    },
    component: () =>
      import('@/views/Gift/searchResult')
  },
  {
    path: '/GiftExpress_detail', // 搜索结果
    name: 'GiftExpress_detail',
    meta: {
      // 页面标题title
      title: '礼品搜索'
    },
    component: () =>
      import('@/views/Gift/GiftExpress_detail')
  },
  // ------------------礼品兑换 end--------------------

  // ------------------设置 star--------------------
  {
    path: '/set_up', // 设置
    name: 'set_up',
    meta: {
      // 页面标题title
      title: '设置'
    },
    component: () =>
      import('@/views/set_up/set_up.vue')
  },
  {
    path: '/set_up/account_security', // 账号与安全
    name: 'account_security',
    component: () =>
      import('@/views/set_up/account_security_new')
  },
  {
    path: '/set_up/addPhone', // 新绑定
    name: 'addPhone',
    component: () =>
      import('@/views/set_up/addPhone')
  },
  {
    path: '/set_up/account_security/phone', // 电话
    name: 'phone',
    component: () =>
      import('@/views/set_up/phone')
  },
  {
    path: '/set_up/account_security/emails', // 邮箱
    name: 'emails',
    component: () =>
      import('@/views/set_up/emails')
  },
  {
    path: '/set_up/account_security/password', // 密码
    name: 'password',
    component: () =>
      import('@/views/set_up/password')
  },
  {
    path: '/set_up/about_us', // 关于我们
    name: 'about_us',
    component: () =>
      import('@/views/set_up/about_us')
  },
  {
    path: '/wxBindingPs', // 关于我们
    name: 'wxBindingPs',
    component: () =>
      import('@/views/login/wxBindingPs')
  },

  // --------------设置  end-------------------------------

  // -------------我的课程  star-------------------------------
  {
    path: '/delHistory', // 回收站
    name: 'delHistory',
    meta: {
      // 页面标题title
      title: '回收站'
    },
    component: () =>
      import('@/views/home/del_history')
  },
  {
    path: '/buylesson', // 已购课程
    name: 'buylesson',
    meta: {
      // 页面标题title
      title: '已购买课程'
    },
    component: () =>
      import('@/views/home/buylesson')
  },
  {
    path: '/expirelesson', // 已购过期
    name: 'expirelesson_lesson',
    meta: {
      // 页面标题title
      title: '已过期课程'
    },
    component: () =>
      import('@/views/home/expirelesson_lesson')
  },
  {
    path: '/index/freelesson', // 已购课程
    name: 'freelesson',
    meta: {
      // 页面标题title
      title: '免费课程'
    },
    component: () =>
      import('@/views/home/free_lesson')
  },
  {
    path: '/index/to_be_paid', // 待付款课程
    name: 'to_be_paid',
    meta: {
      // 页面标题title
      title: '未付款课程'
    },
    component: () =>
      import('@/views/home/to_be_paid')
  },
  {
    path: '/waitorder', // 待付款课程
    name: 'waitorder',
    meta: {
      // 页面标题title
      title: '未付款课程'
    },
    component: () =>
      import('@/views/home/to_be_paid')
  },
  {
    path: '/browselesson', // 浏览课程
    name: 'viewing_record',
    meta: {
      // 页面标题title
      title: '浏览课程'
    },
    component: () =>
      import('@/views/home/viewing_record')
  },
  {
    path: '/index/my_collection', // 课程收藏
    name: 'my_collection',
    meta: {
      // 页面标题title
      title: '我的收藏'
    },
    component: () =>
      import('@/views/home/my_collection')
  },
  {
    path: '/collectlesson', // 课程收藏
    name: 'collectlesson',
    meta: {
      // 页面标题title
      title: '我的收藏'
    },
    component: () =>
      import('@/views/home/my_collection')
  },
  {
    path: '/lessoncollect', // 课程收藏
    name: 'lessoncollect1',
    meta: {
      // 页面标题title
      title: '我的收藏'
    },
    component: () =>
      import('@/views/home/my_collection')
  },
  // -------------我的课程  end-------------------------------
  {
    path: '/activity', // 活动中心
    name: 'Activity',
    meta: {
      // 页面标题title
      title: '活动中心'
    },
    component: () =>
      import('@/views/activity/index')
  },
  // -------------我的社区  star-------------------------------

  {
    path: '/group', // 关注小组
    name: 'group',
    meta: {
      // 页面标题title
      title: '我关注的小组'
    },
    component: () =>
      import('@/views/home/community_xiaozu')
  },
  {
    path: '/index/community_xiaozu', // 关注小组
    name: 'community_xiaozu',
    meta: {
      // 页面标题title
      title: '我关注的小组'
    },
    component: () =>
      import('@/views/home/community_xiaozu')
  },
  {
    path: '/index/community_longyou', // 关注龙友
    name: 'community_longyou',
    meta: {
      // 页面标题title
      title: '我关注的用户'
    },
    component: () =>
      import('@/views/home/community_longyou')
  },
  {
    path: '/user', // 关注龙友
    name: 'user',
    meta: {
      // 页面标题title
      title: '我关注的用户'
    },
    component: () =>
      import('@/views/home/community_longyou')
  },
  {
    path: '/browserthread', // 浏览记录
    name: 'browserthread',
    meta: {
      // 页面标题title
      title: '浏览帖子'
    },
    component: () =>
      import('@/views/home/browsing_history')
  },
  {
    path: '/index/my_reply', // 我的回复
    name: 'my_reply',
    meta: {
      // 页面标题title
      title: '我的回复'
    },
    component: () =>
      import('@/views/home/my_reply')
  },
  {
    path: '/reply', // 我的回复
    name: 'reply',
    meta: {
      // 页面标题title
      title: '我的回复'
    },
    component: () =>
      import('@/views/home/my_reply')
  },
  {
    path: '/index/my_post', // 我的发帖
    name: 'my_post',
    meta: {
      // 页面标题title
      title: '我的发帖'
    },
    component: () =>
      import('@/views/home/my_post')
  },
  {
    path: '/post', // 我的发帖
    name: 'post',
    meta: {
      // 页面标题title
      title: '我的发帖'
    },
    component: () =>
      import('@/views/home/my_post')
  },
  // -------------我的社区  end-------------------------------

  // -------------证书查询  star------------------------------
  {
    path: '/CertificateQuery',
    name: 'CertificateQuery',
    meta: {
      // 页面标题title
      title: '证书查询'
    },
    component: () =>
      import('@/views/CertificateQuery/index')
  },
  {
    path: '/CertificateQueryLook',
    name: 'CertificateQueryLook',
    component: () =>
      import('@/views/CertificateQuery/look')
  },
  // -------------证书查询  end------------------------------

  // -------------我的资料  star-------------------------------

  {
    path: '/collectData1', // 资料收藏
    name: 'collectData1',
    meta: {
      // 页面标题title
      title: '收藏的资料'
    },
    component: () =>
      import('@/views/home/my_data')
  },
  {
    path: '/collectData', // 资料收藏
    name: 'collectData2',
    meta: {
      // 页面标题title
      title: '收藏的资料'
    },
    component: () =>
      import('@/views/home/my_data')
  },
  {
    path: '/index/my_data', // 资料收藏
    name: 'my_data',
    meta: {
      // 页面标题title
      title: '收藏的资料'
    },
    component: () =>
      import('@/views/home/my_data')
  },
  {
    path: '/index/my_updata', // 我的上传
    name: 'my_updata',
    meta: {
      // 页面标题title
      title: '我的上传'
    },
    component: () =>
      import('@/views/home/updata')
  },
  {
    path: '/upload', // 我的上传
    name: 'upload',
    meta: {
      // 页面标题title
      title: '我的上传'
    },
    component: () =>
      import('@/views/home/updata')
  },
  {
    path: '/index/my_down', // 我的下载
    name: 'my_down',
    meta: {
      // 页面标题title
      title: '我的下载'
    },
    component: () =>
      import('@/views/home/down')
  },
  {
    path: '/download', // 我的下载
    name: 'download',
    meta: {
      // 页面标题title
      title: '我的下载'
    },
    component: () =>
      import('@/views/home/down')
  },
  // -------------我的资料  end-------------------------------

  {
    path: '/evaluating', // 评测
    name: 'evaluating1',
    meta: {
      // 页面标题title
      title: '评测记录'
    },
    component: () =>
      import('@/views/home/evaluating')
  },
  {
    path: '/index/evaluating', // 评测
    name: 'evaluating',
    meta: {
      // 页面标题title
      title: '评测记录'
    },
    component: () =>
      import('@/views/home/evaluating')
  },
  {
    path: '/ExchangeCode', //
    name: 'ExchangeCode',
    meta: {
      // 页面标题title
      title: '兑换码'
    },
    component: () =>
      import('@/views/home/ExchangeCode')
  },
  {
    path: '/redeem/get',
    name: 'getExchange',
    meta: {
      // 页面标题title
      title: '领取兑换码'
    },
    component: () =>
      import('@/views/home/getExchange')
  },
  {
    path: '/ExchangeCodeReceivedSuccessfully',
    name: 'ExchangeCodeReceivedSuccessfully',
    meta: {
      // 页面标题
      title: '领取成功'
    },
    component: () =>
      import('@/views/home/ExchangeCodeReceivedSuccessfully')
  },
  {
    path: '/index/express', // 快递
    name: 'express',
    meta: {
      // 页面标题title
      title: '我的快递'
    },
    component: () =>
      import('@/views/home/express')
  },
  {
    path: '/express', // 快递
    name: 'express1',
    meta: {
      // 页面标题title
      title: '我的快递'
    },
    component: () =>
      import('@/views/home/express')
  },
  {
    path: '/express_detail', // 快递详情
    name: 'express_detail',
    component: () =>
      import('@/views/address/express_detail')
  },
  {
    path: '/index/express/address_management', // 地址管理
    name: 'address_management',
    meta: {
      // 页面标题title
      title: '地址管理'
    },
    component: () =>
      import('@/views/address/address_management')
  },
  {
    path: '/index/express/add_address', // 添加地址
    name: 'add_address',
    meta: {
      // 页面标题title
      title: '添加地址'
    },
    component: () =>
      import('@/views/address/add_address')
  },
  {
    path: '/index/express/edit_address', // 修改地址
    name: 'edit',
    meta: {
      // 页面标题title
      title: '修改地址'
    },
    component: () =>
      import('@/views/address/add_address')
  },
  {
    path: '/index/coupon', // 优惠券
    name: 'coupon',
    meta: {
      // 页面标题title
      title: '我的优惠券'
    },
    component: () =>
      import('@/views/home/coupon')
  },
  {
    path: '/coupon', // 优惠券
    name: 'coupon1',
    meta: {
      // 页面标题title
      title: '我的优惠券'
    },
    component: () =>
      import('@/views/home/coupon')
  },
  {
    path: '/index/coupon/give', // 优惠券送朋友
    name: 'couponGive',
    component: () =>
      import('@/views/home/couponGive')
  },
  {
    path: '/index/coupon/receive', // 优惠券领取ReceiveResults
    name: 'couponGive',
    component: () =>
      import('@/views/home/receiveCoupon')
  },
  {
    path: '/index/coupon/ReceiveResults', // 领取结果
    name: 'ReceiveResults',
    component: () =>
      import('@/views/home/ReceiveResults')
  },
  // -------------企业文化考核  start-------------------------------
  {
    path: '/assessment', // 企业文化考核首页
    name: 'Assessment',
    component: () =>
      import('@/views/assessment/index')
  },
  {
    path: '/assessment/cultrue', // 企业文化考核选择
    name: 'Cultrue',
    component: () =>
      import('@/views/assessment/cultrue1')
  },
  // {
  //   path: '/cultruenew', // 企业文化考核选择
  //   name: 'Cultruenew',
  //   component: () =>
  //     import('@/views/assessment/cultrue1')
  // },
  {
    path: '/assessment/exhibition', // 企业文化考核提交
    name: 'Exhibition',
    component: () =>
      import('@/views/assessment/exhibition')
  },
  {
    path: '/assessment/assuccess',
    name: 'Assuccess',
    component: () => import('@/views/assessment/submitsuccess'),
    meta: {
      status: 1
    }
  },

  // -------------企业文化考核  end-------------------------------
  // -------------签到  start-------------------------------
  {
    path: '/signin', // 签到
    name: 'Signin',
    component: () =>
      import('@/views/signin/index')
  },
  {
    path: '/signinsuccess', // 签到成功
    name: 'Signinsuccess',
    component: () =>
      import('@/views/signin/signinsuccess')
  },

  // 发票
  {
    path: '/makeInvoice',
    name: 'MakeInvoice',
    component: () =>
      import('@/views/invoice/makeInvoice')
  },
  // 发票 pdf
  {
    path: '/showInvoice',
    name: 'ShowInvoice',
    component: () =>
      import('@/views/invoice/showInvoice')
  },
  // 新用户注册 填写信息
  {
    path: '/regInfo',
    name: 'RegInfo',
    component: () =>
      import('@/views/regInfo/regInfo')
  },
  // 新用户注册 填写信息
  {
    path: '/registinfo',
    name: 'Registinfo',
    component: () =>
      import('@/views/regInfo/regInfo')
  },
  // 新手 任务中心
  {
    path: '/taskcenter',
    name: 'Taskcenter',
    component: () =>
      import('@/views/newTask/newTask')
  },
  // 新手 任务中心
  {
    path: '/inviteRegister',
    name: 'InviteRegister',
    meta: {
      title: '邀请注册'
    },
    component: () =>
      import('@/views/newTask/inviteRegister')
  },
  // 筑龙币记录
  {
    path: '/coin',
    name: 'Coin',
    component: () =>
      import('@/views/coinRecord/coins')
  },
  {
    path: '/mwxLog',
    name: 'mwxLog',
    component: () =>
      import('@/views/wechat/miniWXLogin.vue')
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () =>
      import('@/views/agreement/agreement.vue')
  },

  // 保障协议
  {
    path: '/agreementPage',
    name: 'AgreementPage',
    component: () =>
      import('@/views/agreement/AgreementPage.vue')
  },

  // 满意度调查
  {
    path: '/index/satisfaction',
    name: 'Satisfaction',
    component: () => import('@/views/home/satisfaction')
  },
  // 全部专题
  {
    path: '/collecttopic',
    name: 'Topic',
    component: () => import('@/views/home/collecttopic')
  },

  // 用户协议
  {
    path: '/protocol',
    name: 'Protocol',
    meta: {
      // 页面标题title
      title: '用户协议'
    },
    component: () => import('@/views/login/protocol')
  },
  // 测试
  {
    path: '/testSth',
    name: 'TestSth',
    meta: {
      // 页面标题title
      title: '用户协议'
    },
    component: () => import('@/views/test.vue')
  },
  {
    path: '/miniWxZlLogin',
    name: 'miniWxZlLogin',
    meta: {
      title: ''
    },
    component: () => import('@/views/wechat/index.vue')
  },
  {
    path: '/standardSeach',
    name: 'standardSeach',
    meta: {
      title: 'AI建筑问答'
    },
    component: () => import('@/views/chatGPT/index.vue')
  },
  {
    path: '/feedBack',
    name: 'FeedBack',
    meta: {
      title: '意见反馈'
    },
    component: () => import('@/views/feedBack/index.vue')
  },
  {
    path: '/standardSeachV2',
    name: 'standardSeachV2',
    meta: {
      title: 'AI建筑问答'
    },
    component: () => import('@/views/chatGPT/standardSeach.vue')
  },
  {
    path: '/chatTransferPage',
    name: 'chatTransferPage',
    meta: {
      title: 'AI建筑问答'
    },
    component: () => import('@/views/chatGPT/transferPage.vue')
  },
  {
    path: '/speechPage',
    name: 'SpeechPage',
    meta: {
      title: 'AI英语老师'
    },
    component: () => import('@/views/chatGPT/speechPage.vue')
  },
  {
    path: '/speechChat_old',
    name: 'SpeechChat_old',
    meta: {
      title: '阿龙和小鱼'
    },
    component: () => import('@/views/chatGPT/speechChat_old.vue')
  },
  {
    path: '/speechChat',
    name: 'SpeechChat',
    meta: {
      title: '阿龙和小鱼'
    },
    component: () => import('@/views/chatGPT/speechChat.vue')
  },
  {
    path: '/speechTest',
    name: 'SpeechTest',
    meta: {
      title: '阿龙和小鱼'
    },
    component: () => import('@/views/chatGPT/speechTest.vue')
  },
  // 关注 公众号
  {
    path: '/att_gzh',
    name: 'att_gzh',
    meta: {
      title: '关注公众号'
    },
    component: () => import('@/views/login/att_gzh.vue')
  },
  // 微信h5 js 授权绑定
  {
    path: '/wechatAuthResult',
    component: () => import('@/views/wechatAuth/authResult.vue'),
    meta: {
      title: ''
    }
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.query.radar) {
    var h = document.createElement('script')
    h.src = 'https://zhulong-lib.oss-cn-beijing.aliyuncs.com/js/time_position_tools.js'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(h, s)
  }
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  var uid = Cookies.get('uid')
  if (uid != null) {
    next()
  } else {
    console.log(to.path, 'to.pathto.pathto.path')
    if (to.path === '/speechChat') {
      // ai英语的特殊处理
      if (WechatState.isWechatMiniprogram) {
        // 小程序允许不登录
        next()
      } else {
        next({
          path: '/login',
          query: { redirect: window.location.href }// 将目的路由地址存入login的query中
        })
      }
    } else {
      if (to.path === '/login' || to.path === '/inviteRegister' || to.path == '/agreement' || to.path === '/forgetPassword' || to.path === '/other_login' || to.path === '/old_login' || to.path === '/mwxLog' || to.path === '/mine' || to.path === '/mine/' || to.path === '/GiftIndex' || to.path === '/gift/giftExchange' || to.path === '/protocol' || to.path === '/chatTransferPage' || to.path === '/miniWxZlLogin' || to.path === '/wechatAuthResult') {
        next()
      } else {
        next({
          path: '/login',
          query: { redirect: window.location.href }// 将目的路由地址存入login的query中
        })
      }
    }
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  /* 当进行页面跳转时定位在页面顶部*/
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
// 解决vue中的NavigationDuplicated {_name: "NavigationDuplicated", name: "NavigationDuplicated"}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router

